<!-- 消息订阅 -->
<template>
  <div class="balance-page">
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>消息订阅
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
      </div>

      <div class="message_subscribe">
        <div class="wechat-list">
          <div class="item">
            <div class="avatar">
              <img
                src="@/assets/icon/gongzhonghao.png"
                alt="avatar"
                class="user-avatar"
              />
              <span>尚未绑定 </span>
            </div>
            <div class="tips">绑定后可用于接受公众号通知消息</div>
            <div>
              <el-button type="primary">绑定</el-button>
            </div>
          </div>
          <div class="item">
            <div class="avatar">
              <img
                src="@/assets/icon/youjian.png"
                alt="avatar"
                class="user-avatar"
              />
              <span>尚未绑定 </span>
            </div>
            <div class="tips">绑定后可用于接受邮件通知消息</div>
            <div>
              <el-button type="primary">绑定</el-button>
            </div>
          </div>
        </div>
      </div>

      <el-table
        border
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column property="serialNum" label="通知类型" align="center" />
        <el-table-column property="eventText" label="通知描述" align="center" />
        <el-table-column property="amount" label="公众号通知" align="center" />
        <el-table-column property="typeText" label="邮件通知" align="center" />
      </el-table>

      <!-- 分页 -->
      <div style="float: right; padding: 20px">
        <pagination
          :total="total"
          :current-page="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div>

      <!-- <Card title="收支明细">
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column property="datelineReadable" label="时间" width="320" />
        <el-table-column property="amount" label="金额" width="180" />
        <el-table-column property="typeText" label="类型" width="180" />
        <el-table-column property="eventText" label="事件" />
      </el-table>
    </Card> -->
    </el-card>
  </div>
</template>

<script>
import { list } from "@/api/balance";

import { mapState } from "vuex";
export default {
  data() {
    return {
      activeName: "1",
      commissionData: [],
      itemData: [
        [
          { icon: require("@/assets/icon/1.png"), title: "可用金额", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "累计充值", num: 0 },
        ],
        [
          { icon: require("@/assets/icon/1.png"), title: "冻结金额", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "累计提现", num: 0 },
        ],
        [
          { icon: require("@/assets/icon/1.png"), title: "可提现", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "提现中", num: 0 },
        ],
      ],
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  components: {
    // Card,
    // Button,
  },
  filters: {
    price(value) {
      return "￥" + value;
    },
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      balanceAllowTransfer: (state) => state.balance.balanceAllowTransfer,

      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      totals: (state) => state.balance.totals,
      statusText: (state) => state.user.statusText,
    }),
  },
  created() {
    this.$store.dispatch("user/info");
    this.$store.dispatch("balance/detail").then(() => {
      this.itemData[0][0].num = this.balanceTotal;
      this.itemData[0][1].num = this.totalRechargeSuccess;
      this.itemData[1][0].num = this.totalWithdrawRejected;
      this.itemData[1][1].num = this.totalWithdrawSuccess;
      this.itemData[2][0].num = this.balanceCanWithdraw;
      this.itemData[2][1].num = this.totalWithdrawing;
    });
    //资金明细
    this.loadData();

    //佣金明细
    this.$store.dispatch("commission/list", {}).then(() => {
      this.commissionData = this.list;
    });
  },
  methods: {
    /**
     * 结算到余额
     */
    handleSettlement() {
      // this.$router.push('/settlement')
      this.$store.dispatch("commission/cash").then(() => {
        this.$router.push("/balance");
      });
    },

    /**
     * 查询列表
     */
    loadData() {
      new Promise((resolve, rejust) => {
        list(this.queryParams)
          .then((res) => {
            this.tableData = res.result.list;
            this.total = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum;
      this.loadData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParams.pageSize = val;
      this.loadData();
    },

    goCash() {
      let mainRoute = this.$route.path.split("/")[1];
      let subRoute;

      if (mainRoute === "balance") {
        subRoute = "cash";
      } else if (mainRoute === "balance2") {
        subRoute = "cash2";
      } else if (mainRoute === "balance3") {
        subRoute = "cash3";
      } else {
        return;
      }
      this.$router.push(subRoute);
    },
  },
};
</script>

<style scoped>
.custom-descriptions .el-descriptions-item__label {
  font-weight: bold;
  color: #333;
}

.custom-descriptions .el-descriptions-item__content {
  color: #666;
}

.custom-descriptions .el-descriptions-item {
  padding: 8px 0;
}

.custom-descriptions .el-descriptions__item {
  border-bottom: 1px solid #eee;
}

.highlight-text {
  color: red;
  font-weight: bold;
}

.balance-box .money-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 60px;
}

.balance-box .money-box .money-item {
  display: inline-block;
  text-align: center;
}

.balance-box .money-box .money-item span {
  color: #969696;
  font-size: 14px;
}
.balance-box .money-box .money-item p {
  margin-top: 20px;
  font-weight: 700;
  font-size: 32px;
  color: #e93323;
}

.balance-box .tab-box {
  margin: 30px 0 0;
}

/* 通知绑定css */

.message_subscribe .wechat-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  padding: 10px 0px;
}

.message_subscribe .wechat-list .item {
  padding: 0 37px;
  height: 100px;
  opacity: 1;
  border: 1px solid #e8ecf0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message_subscribe .wechat-list .item .avatar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;
  width: 300px;
}

.message_subscribe .wechat-list .item .avatar span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message_subscribe .wechat-list .item .avatar .user-avatar {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.message_subscribe .wechat-list .item .tips {
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;
  flex: 1;
  text-align: start;
}

.message_subscribe .wechat-list .item .btn-box button {
  width: 80px;
  height: 40px;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 1px solid;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #656262;
  outline: none;
  background-color: #fff;
  cursor: pointer;
}

.message_subscribe .wechat-list .item .btn-box .bind {
  color: #1c95ff;
  border-color: #1c95ff;
}

.message_subscribe .wechat-list .item .btn-box .bind:hover {
  box-shadow: 1px 1px 6px 1px #1c95ff;
}

.message_subscribe .wechat-list .item .btn-box .unbind {
  color: #656262;
  border-color: #656262;
}

.message_subscribe .wechat-list .item .btn-box .unbind:hover {
  box-shadow: 1px 1px 6px 1px #656262;
}
</style>
