<template>
  <div class="credit-page">
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>推广分销
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
      </div>

      <el-alert type="success">
        温馨提示:
        1、由您邀请注册的用户每次下单成交的订单，你可以得到一定比例的佣金；
      </el-alert>

      <div class="invited-box" style="margin: 50px">
        <div class="money-box">
          <div class="money-item">
            <span>共邀请好友</span>
            <p>{{ invitedTotal }}</p>
          </div>

          <div class="money-item">
            <span>累计收益(元)</span>
            <p>{{ profitTotal }}</p>
          </div>
        </div>
      </div>

      <el-form
        label-position="left"
        label-width="80px"
        style="margin-top: 20px"
      >
        <el-form-item label="邀请码">
          <el-input v-model="inviteCode"></el-input>
        </el-form-item>

        <el-form-item label="邀请链接">
          <el-input v-model="inviteUrl"></el-input>
        </el-form-item>

        <el-form-item label="邀请海报">
          <el-button type="warning" size="small" @click="imageSharing"
            >立即生成</el-button
          >
        </el-form-item>

        <!-- <el-form-item label="二维码">
          <p class="content-p">
            <img :src="inviteUrlQrImageUrl" alt="" />
          </p>
        </el-form-item> -->
      </el-form>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="一级下家" name="first" :item="1">
          <el-table
            border
            :data="invitedList"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column prop="userId" label="用户编号" align="center" />

            <el-table-column prop="username" label="用户账号" align="center" />

            <el-table-column
              prop="datelineCreateReadable"
              label="注册时间"
              align="center"
            />
          </el-table>
          <pagination
            :total="total"
            :current-page="queryParam.pageNum"
            :page-size="queryParam.pageSize"
            @currentChange="handleCurrentChange"
            @editPagesizes="editPageSizes"
          />
        </el-tab-pane>
        <el-tab-pane label="二级下家" name="second" :item="2">
          <el-table
            border
            :data="invitedList"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column prop="userId" label="用户编号" align="center" />

            <el-table-column prop="username" label="用户账号" align="center" />

            <el-table-column
              prop="datelineCreateReadable"
              label="注册时间"
              align="center"
            />
          </el-table>
          <pagination
            :total="total"
            :current-page="queryParam.pageNum"
            :page-size="queryParam.pageSize"
            @currentChange="handleCurrentChange"
            @editPagesizes="editPageSizes"
          />
        </el-tab-pane>
        <el-tab-pane label="三级下家" name="third" :item="3">
          <el-table
            border
            :data="invitedList"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column prop="userId" label="用户编号" align="center" />

            <el-table-column prop="username" label="用户账号" align="center" />

            <el-table-column
              prop="datelineCreateReadable"
              label="注册时间"
              align="center"
            />
          </el-table>
          <pagination
            :total="total"
            :current-page="queryParam.pageNum"
            :page-size="queryParam.pageSize"
            @currentChange="handleCurrentChange"
            @editPagesizes="editPageSizes"
          />
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <!-- 分享海报组件 -->
    <FenXiangHaiBao ref="HaiBaoComponent"></FenXiangHaiBao>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";

import { invited_list, invitedStatis } from "@/api/user";

import FenXiangHaiBao from "@/pages/mobile/HaiBao.vue";

export default {
  data() {
    return {
      activeName: "first",
      number: [1, 1, 1],
      invitedList: [],
      moneyData: [50, 100, 200, 500],
      payType: [
        {
          img: require("@/assets/image/weixin.png"),
          title: "微信",
          value: "wxpay",
        },
        {
          img: require("@/assets/image/alipay.png"),
          title: "支付宝",
          value: "alipay",
        },
        {
          img: require("@/assets/image/paypal.png"),
          title: "Paypal",
          value: "paypal",
        },
      ],
      from: {
        amount: 0,
        verifyCode: null,
        verifyCodeType: 1,
        platform: "wxpay",
        platformAccount: "",
        platformPerson: "",
      },
      CodeType: [
        { name: "邮箱", id: 1 },
        { name: "手机", id: 2 },
      ],
      valuePay: "微信",
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        inviterLevel: 1,
      },
      invitedTotal: undefined,
      profitTotal: undefined,
    };
  },
  components: {
    Pagination,
    FenXiangHaiBao,
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      invited_list: (state) => state.user.invited_list,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
    }),
  },
  created() {
    //加载用户信息
    this.$store.dispatch("user/info");

    this.loadData();

    //查询收益统计
    this.queryInvitedStatis();
  },
  methods: {
    /**
     * 查询收益统计
     */
    queryInvitedStatis() {
      var data = {};
      new Promise((resolve, rejust) => {
        invitedStatis(data)
          .then((res) => {
            this.invitedTotal = res.result.invitedTotal;
            this.profitTotal = res.result.profitTotal;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 图片分享
     */
    imageSharing() {
      // Toast({
      //   message: "很快就会推出。敬请期待!",
      // });
      this.$refs.HaiBaoComponent.SubmitOpen();
    },

    handleCash() {
      this.$store.dispatch("withdraw/create", { ...this.from });
    },
    handleCode() {
      this.$store.dispatch("withdraw/code", {
        verifyCodeType: this.from.verifyCodeType,
      });
    },
    changePayType(value, type) {
      console.log(value);
      this.valuePay = value;
      this.from.platform = type;
    },

    handleClick(tab) {
      this.queryParam.inviterLevel = tab.$attrs.item;

      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;

      this.invitedList = [];
      this.loadData();
    },

    /**
     * 加载列表
     */
    loadData() {
      var then = this;
      new Promise((resolve, rejust) => {
        invited_list(this.queryParam)
          .then((res) => {
            then.invitedList = res.result.list;
            then.total = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });

      // this.number[value.item] = value.number;
      // let data = { offset: (this.number[value.item] - 1) * 10, limit: 10 };
      // data.inviterLevel = value.item + 1;

      // this.queryParam.inviterLevel = value.item + 1;

      // this.$store.dispatch("user/invited_list", this.queryParam).then(() => {
      //   this.lists = this.invited_list;
      //   this.$forceUpdate();
      // });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.loadData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.loadData();
    },
  },
};
</script>

<style scoped>
.back-img {
  height: 20px;
  margin-right: 10px;
}
.back-btn {
  width: 140px;
  height: 40px;
  color: #5f5f5f;
  border: 2px solid #5f5f5f;
  line-height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  font-size: 14px;
}
.back-btn.active {
  border: 2px solid #014785;
}
p {
  padding: 0px;
  margin: 0px;
  color: #5f5f5f;
  font-size: 12px;
}
.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
}
.content {
  margin-left: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #1b1b1b;
  opacity: 1;
}
.input {
  margin-left: 10px;
  margin-right: 10px;
}
.item.active {
  background: #f8f8f8;
  padding: 10px 20px;
  margin-right: 40px;
  box-sizing: border-box;
  /* padding-right: 40px;  */
  /* width: auto; */
}
.input-title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
  margin-left: 20px;
}

.invited-box .money-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 60px;
}

.invited-box .money-box .money-item {
  display: inline-block;
  text-align: center;
}

.invited-box .money-box .money-item span {
  color: #969696;
  font-size: 14px;
}
.invited-box .money-box .money-item p {
  margin-top: 20px;
  font-weight: 700;
  font-size: 32px;
  color: #e93323;
}

.invited-box .tab-box {
  margin: 30px 0 0;
}
</style>
