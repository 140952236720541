<template>
  <div>
    <van-nav-bar
      title="我的佣金"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="my-wallet">
      <div class="back-img"></div>
      <div class="wallet-box">
        <div class="top">
          <div class="item">
            <div class="label">账户佣金</div>
            <div class="value">{{ balanceAllowTransfer }}</div>
          </div>
          <div class="btn" @click="handleSettlement">转算到余额</div>
        </div>
        <div class="bottom">
          <div class="item">
            <div class="label">总佣金</div>
            <div class="value">{{ balanceAll }}</div>
          </div>
          <!-- <div class="item">
            <div class="label">总佣金</div>
            <div class="value">43.20</div>
          </div> -->
        </div>
      </div>
      <div class="wallet-list">
        <div class="title"><div class="item active">佣金明细</div></div>
        <div class="box-list">
          <div
            class="item"
            v-for="(value, key, index) in commissionData"
            :key="index"
          >
            <div class="item-title">
              <div class="label">下级购买订单收益</div>
              <div class="time">{{ value.datelineReadable }}</div>
            </div>
            <div class="item-money">+{{ value.amount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { commissionList } from "@/api/commisson";

export default {
  components: {},
  data() {
    return {
      commissionData: [],
      show: false,
      id: "",
      amount: undefined,
      radio: "",
      payMaps: [],
      qrCodeUrl: "",
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      balanceAll: (state) => state.commission.balanceAll,
      balanceAllowTransfer: (state) => state.balance.balanceAllowTransfer,
    }),
  },
  created() {
    document.title = "我的佣金";

    //查询我的余额
    this.$store.dispatch("balance/detail");

    //查询佣金明细
    this.getCommission();
  },
  methods: {
    /**
     * 结算到余额
     */
    handleSettlement() {
      this.$store.dispatch("commission/cash").then(() => {
        // this.$router.push("/balance");

        this.$store.dispatch("balance/detail");
      });
    },

    /**
     * 查询佣金明细
     */
    getCommission() {
      new Promise((resolve, rejust) => {
        commissionList({})
          .then((res) => {
            this.commissionData = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 返回上页
     */
    onClickLeft() {
      this.$router.push("/my");
    },
  },
  // 离开页面销毁轮询器
  destroyed() {
    clearInterval(this.T);
  },
};
</script>
<style scoped>
.my-wallet {
  background: url(../../assets/card_img_bg.png) no-repeat center center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-size: 414px 276px;
  background-position: 0 -82px;
  background-repeat: no-repeat;
  background-color: #faf5f7;
  padding: 0px 10px;
}
.my-wallet .wallet-box {
  background: url(../../assets/kapian.png) no-repeat center center;
  background-size: 100% 219px;
  background-repeat: no-repeat;
  width: 100%;
  height: 219px;
  border-radius: 11px;
  margin-top: 22px;
  padding: 33px 27px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.my-wallet .wallet-box .top,
.my-wallet .wallet-box .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.my-wallet .wallet-box .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 40%;
}
.my-wallet .wallet-box .btn {
  /* width: 88px; */
  height: 40px;
  background: #ededed;
  box-shadow: 0 1px 3px #fff, inset 0 2px 4px hsla(0, 0%, 100%, 0.8);
  border-radius: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #020000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-wallet .wallet-box .item .label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(247, 201, 148, 0.7);
}
.my-wallet .wallet-box .bottom .item .value {
  font-size: 27px;
  margin-top: 10px;
}
.my-wallet .wallet-box .item .value {
  font-size: 33px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
}
.back-img {
  position: fixed;
  left: 11px;
  top: 11px;
  width: 33px;
  height: 33px;
  z-index: 500;
}
.back-img:after {
  content: "";
  position: absolute;
  background-size: cover;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 33px;
  width: 33px;
}
.my-wallet .wallet-list {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  margin-top: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.my-wallet .wallet-list .title {
  width: 100%;
  padding: 14px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 33px;
}
.my-wallet .wallet-list .title .item {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1e2331;
  position: relative;
}
.my-wallet .wallet-list .title .active:after {
  position: absolute;
  content: "";
  width: 66px;
  height: 4px;
  background: rgba(253, 77, 32, 0.2);
  bottom: 20%;
  left: 0;
}
.my-wallet .wallet-list .box-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}
.my-wallet .wallet-list .box-list .item {
  width: 100%;
  padding: 15px;
  height: 87px;
  border-bottom: 1px solid #d7dbe1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-wallet .wallet-list .box-list .item:first-child {
  height: 55px;
}
.my-wallet .wallet-list .box-list .item:last-child {
  border: none;
}
.my-wallet .wallet-list .box-list .item .item-title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
}
.my-wallet .wallet-list .box-list .item .item-title .label {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e2331;
}
.my-wallet .wallet-list .box-list .item .item-title .time {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c4c7cf;
}
.my-wallet .wallet-list .box-list .item .item-money {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e2331;
}
</style>
