<template>
  <div class="hot">
    <van-nav-bar
      left-arrow
      @click-left="onClickLeft"
      style="background-color: #3072fb"
      class="custom-navbar"
    >
      <template #left>
        <span class="custom-tab-title" @click-left="onClickLeft">
          <van-icon name="arrow-left" color="#FFFFFF" />返回</span
        >
      </template>
    </van-nav-bar>
    <!-- 顶部返回，提醒 -->
    <div class="hot-top-bar">
      <i></i>
      <div class="title"><img src="../../assets/image/xsms.png" alt="" /></div>
      <div class="message"></div>
    </div>
    <div class="container">
      <!-- <van-tabs class="custom-tabs" animated>
        <van-tab title="爆款推荐" style="border-radius: 15px"> -->
      <div
        class="group list active"
        v-for="(value, key, index) in recommendLists"
        :key="index"
        @click="toProductDetails(value)"
      >
        <div class="item">
          <div class="l">
            <img :src="value.picUrl" />
          </div>
          <div class="r">
            <p class="title">
              {{ value.productName }}
            </p>
            <div class="buy">
              <div class="hot_prodcut_price">
                <span><i>￥</i>{{ value.price }}</span>
                <!-- <span class="through">￥399</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </van-tab> -->

      <!-- <van-tab title="热门商品">
          <div
            class="group list active"
            v-for="(value, key, index) in hotLists"
            :key="index"
            @click="toProductDetails(value)"
          >
            <div class="item">
              <div class="l">
                <img :src="value.picUrl" />
              </div>
              <div class="r">
                <p class="title">
                  {{ value.productName }}
                </p>
                <div class="buy">
                  <div class="price">
                    <span><i>￥</i>{{ value.price }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div></van-tab
        > -->
      <!-- </van-tabs> -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "index",

  data() {
    return {
      show: true,
      active: 1,
      catesList: [],
      recommendLists: [],
      indexBrandLists: [],
      hotLists: [],
      // bannerList:[]
      queryParam: {
        catesType: undefined,
        catesId: undefined,
        code: undefined,
      },
    };
  },

  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      bannerList: (state) => state.index.bannerList,
      newList: (state) => state.index.newList,
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
      indexBrandList: (state) => state.index.indexBrandList,
      // normalNewsList: (state) => state.index.normalNewsList,
    }),
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };

      //前台获取推荐产品列
      this.$store.dispatch("index/queryRecommendList", data).then(() => {
        this.recommendLists = this.recommendList;
        console.log(this.recommendLists);
        // this.$forceUpdate();
      });

      //前台获取热卖产品列表
      this.$store.dispatch("index/queryHotList", data).then(() => {
        this.hotLists = this.hotList;
        console.log(this.hotLists);
        // this.$forceUpdate();
      });
    },

    //跳转商品详情界面
    toProductDetails(v) {
      // this.$router.push({
      //   path: "product_detail",
      //   query: { product_id: v.id },
      // });
      this.$router.push(`/product_detail/${v.id}`);
    },

    /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.push("/mindex");
    },
  },
};
</script>
<style scoped>
.hot {
  width: 100%;
  overflow-x: hidden;
  background: url(../../assets/image/bg.png) top -0.65rem left no-repeat #f4f5f6;
  background-size: 100% auto;
  color: #ffffff;
}
/*顶部返回，提醒*/
.hot-top-bar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  position: relative;
}
.hot-top-bar i {
  display: inline-block;
  width: 0.5rem;
  height: 1.2rem;
  /* background: url(../images/icon-back.png) left center no-repeat; */
  background-size: 0.17rem 0.3rem;
}
.hot-top-bar .title {
  height: 25px;
}
.hot-top-bar .title img {
  height: 100%;
  width: auto;
  vertical-align: top;
}
.hot-top-bar .message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hot-top-bar .message img {
  width: 0.26rem;
  padding-bottom: 0.05rem;
}
.hot-top-bar .message span {
  font-size: 3.2px;
}
.container {
  width: 100%;
  padding: 5.4px 5.4px 9px 5.4px;
}
/* 时间段 */
.time-section {
  width: 100%;
  padding: 0 0.3rem;
}
.time-section ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.time-section ul li {
  font-size: 0.32rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 0.8rem;
  position: relative;
  text-align: center;
}
.time-section ul li span.status {
  font-size: 0.24rem;
}
.time-section ul li span:last-child {
  margin-top: 0.05rem;
}
.time-section ul li:first-child {
  font-size: 0.28rem;
  text-align: left;
}
.time-section ul li:last-child {
  font-size: 0.28rem;
  text-align: right;
}
.time-section ul li.active::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.05rem;
  background: #fff000;
  border-radius: 0.05rem;
  bottom: -0.12rem;
}
/*倒计时*/
.count-down {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.26rem;
  color: #999999;
  padding: 0.1rem 0 3.2px 0;
}
.count-down .time {
  display: flex;
  align-items: center;
}
.count-down .time i {
  padding-right: 3.2px;
}
.count-down .time span {
  display: inline-block;
  padding: 0.05rem 0.15rem;
  font-size: 0.22rem;
  color: #ffffff;
  background: #fe1a1c;
  margin: 0 0.06rem;
  border-radius: 0.1rem;
}
/* 推荐 */
.recommend {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.15rem;
  background: #ffffff;
  border-radius: 0.3rem;
  padding-top: 0.25rem;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}
.recommend h2 {
  display: flex;
  align-items: baseline;
  font-size: 0.32rem;
  color: #333333;
  padding-bottom: 0.15rem;
}
.recommend h2 i {
  flex-shrink: 0;
  width: 0.36rem;
  height: 0.38rem;
  /* background: url(../images/icon-zan.png) center center no-repeat; */
  background-size: 100%;
  margin: 0 0.15rem;
}
.recommend h2 span {
  font-size: 0.24rem;
  color: #999999;
  padding-left: 3.2px;
}
.group {
  width: 100%;
}
.group .item {
  width: 100%;
  padding: 20px 15px;
  background: #ffffff;
  border-radius: 15px;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.group.recommend .item {
  padding-top: 0;
  margin-bottom: 0rem;
}
.group .item .l {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin-right: 1rem;
}

.group .item .l img {
  width: 100%;
  border-radius: 10px;
}
.group .item .r {
  width: 100%;
  margin-bottom: 10px;
}
.group .item .r .title {
  font-size: 16px;
  color: #333333;
  line-height: 25px;
}
.group .item .r .progress {
  width: 2.55rem;
  height: 0.28rem;
  line-height: 0.28rem;
  background: #ffd6c3;
  border-radius: 0.28rem;
  font-size: 0.19rem;
  color: #ffffff;
  margin-top: 0.3rem;
}
.group .item .r .progress span {
  display: inline-block;
  text-align: center;
  background: #fe8578;
  border-radius: 0.28rem;
  font-size: inherit;
}
.group .item .r .progress2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3.2px;
  line-height: 3.2px;
  margin-top: 0.15rem;
  font-size: 0.16rem;
  color: #666666;
}
.group .item .r .progress2 span {
  display: block;
  flex-shrink: 0;
  width: 1.5rem;
  height: 100%;
  background: #ffd6c3;
  border-radius: 3.2px;
  text-align: center;
  margin-left: 0.15rem;
}
.group .item .r .progress2 span i {
  float: left;
  display: inline-block;
  height: 100%;
  background: #fe8578;
  border-radius: 3.2px;
  font-size: inherit;
}
.group .item .r .buy {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3.2px;
}

.group .item .r .buy .hot_prodcut_price {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  justify-content: center;
}

.group .item .r .buy .hot_prodcut_price span {
  font-size: 20px;
  color: #f35a32;
  font-weight: bold;
}

.group .item .r .buy .hot_prodcut_price span i {
  font-size: 14px;
}

.group .item .r .buy .hot_prodcut_price span.through {
  text-decoration: line-through;
  color: #999999;
  font-size: 0.22rem;
}

.group .item .r .buy .btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.group .item .r .buy .btn a {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 1.2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.5rem;
  border: 0.01rem solid #fd181d;
  font-size: 0.24rem;
  color: #fd181d;
}
.group .item .r .buy .btn a i {
  display: block;
  width: 0.12rem;
  height: 3.2px;
  /* background: url(../images/arrow-right.png) center center no-repeat; */
  background-size: 0.12rem auto;
  margin-left: 0.05rem;
}
/* 团购列表 */
.group.list {
  display: none;
}
.group.list.active {
  display: block;
  margin-top: 10px;
}
.group.list .item p.subtitle {
  font-size: 0.24rem;
  color: #fd181d;
  padding-top: 0.15rem;
  padding-bottom: 0.3rem;
}
.custom-navbar .van-nav-bar__text {
  color: #f00 !important; /* 修改中间文字颜色 */
}

.custom-navbar .van-nav-bar__left .van-nav-bar__text {
  color: #ff0 !important; /* 修改左侧文字颜色 */
}
.custom-tabs .van-tab {
  border-radius: 15px;
}
</style>
